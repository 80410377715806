<!-- ShowPasswordToggle.vue -->
<script setup>
  import { ref, watch } from 'vue';

  const showPassword = ref(false);

  const props = defineProps(['modelValue']);
  const emit = defineEmits(['update:modelValue']);

  watch(showPassword, (newValue) => {
    emit('update:modelValue', newValue);
  });

  const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
  };
</script>

<template>
  <div class="flex justify-start">
    <i
      v-if="modelValue"
      class="fa-regular fa-eye mt-0.5 w-4 cursor-pointer text-orange-600"
      @click="togglePasswordVisibility"></i>
    <i
      v-else
      class="fa-regular fa-eye-slash mt-0.5 w-4 cursor-pointer text-orange-600"
      @click="togglePasswordVisibility"></i>
    <span class="ms-2 text-sm text-gray-800">Show password</span>
  </div>
</template>
